import React from 'react';
import { Link } from 'react-router-dom';

class Lottery extends React.Component {

  render() {
    return (
      <Link to="/lottery" target="_blank" rel="noopener noreferrer" className="btn btn-black me-2">
        <i className="fa fa-dice"></i> Undian
      </Link>
    );
  }
};

export default Lottery;
