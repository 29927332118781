import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Services from '../../services/service';
import { RESPONSE, MODALACTION } from '../../config/constants';
import { useForm } from 'react-hook-form';
import { Panel, PanelBody } from '../../components/panel/panel';

const { SUCCESS, FAILED } = RESPONSE;
const { CREATE, EDIT } = MODALACTION;

const ModalAddParticipant = ({isOpen, toggle, toggleNotification, setLoading, selectedParticipant = null, modalAction}) => {

  const [participant, setParticipant] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const [loadingModal, setLoadingModal] = useState(false); 

  useEffect(() => {
    const getDetail = () => {
      Services.getParticipantDetail(selectedParticipant)
      .then(async (res) =>{
        reset(res);
        setParticipant(res);
        // setState(prevState => ({
        //   ...prevState,
        //   }));
        setLoadingModal(false);
      }).catch(error =>{
        setLoadingModal(false);
        console.log('error get detail', error);
        if (error.response.data && error.response.data.error) {
          toggleNotification(FAILED, error.response.data.error);
        } else {
          toggleNotification(FAILED, error.response.statusCode);
        }
      });
    }

    if(selectedParticipant){
      setLoadingModal(true);
      getDetail();
    }
  }, [selectedParticipant, toggleNotification, reset]);

  const onSubmit = async (data, action) => {
    // console.log('data', data, action);
    const {
      title,
      name,
      agencyTeam,
      identityNumber,
      videoFreestyle,
      tableNo,
    } = data;

    const payload = {
      title,
      name,
      agencyTeam,
      identityNumber,
      videoFreestyle,
      tableNo
    };

    // console.log('payload', payload);
    if(action === CREATE){
      try {
        const res = await Services.postParticipant(payload);
        toggleNotification(SUCCESS, res.message);
        toggle(null);
        setLoading(true);
      } catch (error) {
        console.log('error register participant', error);
        if (error.response.data && error.response.data.error) {
          toggleNotification(FAILED, error.response.data.error);
        } else {
          toggleNotification(FAILED, error.response.statusCode);
        }
      }
    }else if(action === EDIT){
      try {
        const res = await Services.putParticipant(selectedParticipant, payload);
        toggleNotification(SUCCESS, res.message);
        toggle(null);
        setLoading(true);
      } catch (error) {
        console.log('error edit participant', error);
        if (error.response.data && error.response.data.error) {
          toggleNotification(FAILED, error.response.data.error);
        } else {
          toggleNotification(FAILED, error.response.statusCode);
        }
      }
    }
  };

  const onError = (errors) => {
    console.log(errors);
    toggleNotification(
      FAILED,
      'Ada data yang salah / belum lengkap. Silahkan cek kembali.'
    );
  };

  const headerText = (modalAction === EDIT ? 'Edit' : 'Tambah') + ' Data Peserta';

  return (
    <Modal isOpen={isOpen} toggle={() =>toggle(null)} size="lg">
      <ModalHeader toggle={() =>toggle(null)}>{headerText}</ModalHeader>
      <ModalBody>
        <Panel className={loadingModal ? 'panel-loading':''}>
          <PanelBody className="p-0">
            {(loadingModal && 
              <div className="panel-loader">
                <span className="spinner spinner-sm"></span>
              </div>
            )}
            <div className="fs-13px">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label className="mb-2">
                    Area
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.title ? 'is-invalid' : ''
                    }`}
                    {...register(`title`)}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="mb-2">
                    No. KTP
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.identityNumber ? 'is-invalid' : ''
                    }`}
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    {...register(`identityNumber`, {
                      required: false
                      // required: 'Input data harus diisi.',
                    })}
                  />
                  <div className="invalid-feedback">{errors.identityNumber?.message}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label className="mb-2">
                    Nama Lengkap<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    {...register(`name`, {
                      required: 'Input data harus diisi.',
                      minLength: {
                        value: 3,
                        message: 'Minimal 3 karakter.',
                      },
                    })}
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="col-lg-6">
                  <label className="mb-2">
                    Buyer Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.agencyTeam ? 'is-invalid' : ''
                    }`}
                    {...register(`agencyTeam`, {
                      required: 'Input data harus diisi.',
                      minLength: {
                        value: 3,
                        message: 'Minimal 3 karakter.',
                      },
                    })}
                  />
                  <div className="invalid-feedback">
                    {errors.agencyTeam?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-6">
                  <label className="mb-2">
                    Nomor Kamar
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.videoFreestyle ? 'is-invalid' : ''
                    }`}
                    {...register(`videoFreestyle`)}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="mb-2">
                    Nomor Meja
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-13px ${
                      errors.tableNo ? 'is-invalid' : ''
                    }`}
                    // maxLength={13}
                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    {...register(`tableNo`)}
                  />
                </div>
              </div>
              {participant?.logSendEmail &&
                <div className='row'>
                  <div className='col-lg-12'>
                    <label className='mb-2'>
                      Log Send Email
                    </label>
                    <textarea 
                      className='form-control'
                      disabled
                      defaultValue={participant.logSendEmail}
                    />
                  </div>
                </div>
              }
            </div>
          </PanelBody>
        </Panel>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-white btn-sm"
          onClick={() => toggle(null)}
        >
          <i className="fa fa-times"/> Batal
        </button>
        {(modalAction === CREATE) &&
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleSubmit(onSubmit, onError)(CREATE)}
          >
            <i className="fa fa-plus"/> Tambah
          </button>
        }
        {(modalAction === EDIT) &&
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleSubmit(onSubmit, onError)(EDIT)}
          >
            <i className="fa fa-edit"/> Edit
          </button>
        }
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddParticipant;
