// import Services from './service';
import { isExpired } from "react-jwt";
import UserProfile from "./userProfile";
const userProfile = new UserProfile();
export default class Auth {

  setSession(authResult) {
    // Set the time that the access token will expire at
    localStorage.setItem('token', authResult.token);
    setTimeout(() => {
      userProfile.setUserProfile(authResult.token).then(res =>{
        window.location.replace('/');
      }).catch(err => console.log(err));
    }, 1000);
  }

  clearLocalStorage() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('user-profile');
    // localStorage.removeItem('username');
    localStorage.clear();
  }
  
  logout() {
    // Clear access token and ID token from local storage
    this.clearLocalStorage();
    window.location.replace('/login');
  }

  isAuthenticated() {
    const token = localStorage.getItem('token');
    const isTokenExpired = isExpired(token);
    if(isTokenExpired) {
      this.clearLocalStorage();
      return false;
    }
    return true;
  }
}
