import React from 'react';
import { AppSettings } from '../../../config/app-settings.js';
import withNotification from '../../../components/HOCNotification/HOCNotification.component.js';
import LogoClient from '../../../assets/img/sinarmas/logo.png';
import LogoEvent from '../../../assets/img/sinarmas/logo-great-star.png';
// import EventBG from '../../../assets/img/sinarmas/BG.jpg';
import AudioRolling from '../../../assets/audio/rolling.wav';
import AudioRing from '../../../assets/audio/ring.wav';
import Services from '../../../services/service.js';
import UserProfile from '../../../services/userProfile';
import VideoBG from '../../../assets/video/bg-undian-sinarmas-2.mp4';

import LotteryScript from './script.js';

import './css/style2.css';
import './css/lottery.css';
import './css/skin/skin-11.css';

const userProfile = new UserProfile();

// const { SUCCESS, FAILED } = RESPONSE;
class Lottery extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      event: {},
      winner: {},
      listParticipants: [],
      isRolling: false,
      hasWinner: false,
      animationTimer: null,
    };
    this.getEventData = this.getEventData.bind(this);
    this.startLottery = this.startLottery.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keypress", this.handleKeydown, true);
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    this.getEventData();
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeydown, true);
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  getEventData() {
    const params = {
      id: userProfile.getSelectedEvent().value
    };
    Services.getEventDetail(params).then(res =>{
      // console.log(res);
      this.setState({event: res}, 
        () => {
        this.getListLottery();
      });
    }).catch(error =>{
      console.log('failed to get event detail', error);
    });
  }

  getListLottery(){
    const params ={
      eventId: this.state.event._id
    };
    Services.getListParticipantLottery(params)
    .then(res =>{
      this.setState({listParticipants: res});
    })
  }

  handleRoll() {
    //output number
    const output1 = document.getElementById('output1');
    const output2 = document.getElementById('output2');
    const output3 = document.getElementById('output3');
    const output4 = document.getElementById('output4');

    const animationTimer = setInterval(function () {
      output1.innerHTML = Math.floor(Math.random() * 100);
      output2.innerHTML = Math.floor(Math.random() * 100);
      output3.innerHTML = Math.floor(Math.random() * 100);
      output4.innerHTML = Math.floor(Math.random() * 100);
    }, 90);
    this.setState({animationTimer});
  }

  startLottery() {
    console.log('start lottery');
    this.setState({ isRolling: true, hasWinner: false, winner: null }, ()=>{
      this.handleRoll();
      const x = document.getElementById('audio1');
      x.play();
    });
  }

  stopLottery(){
    console.log('stop lottery');
    //TODO get winner
    const winner  = this.getWinner();
    this.setState({ 
      isRolling: false, 
      hasWinner: true,
      winner,
    });
    clearInterval(this.state.animationTimer);
    const x = document.getElementById('audio1');
    const y = document.getElementById('audio2');
    x.pause();
    y.play();
  }

  getWinner(){
    const {listParticipants} = this.state;
    const idx = Math.floor(Math.random() * listParticipants.length);
    const participant = listParticipants[idx];
    return {
      idx, 
      ...participant,
      numSplit: participant.participantNumber.match(/.{1,2}/g)
    };
  }

  chooseWinner(){
    const {winner, listParticipants} = this.state;
    Services.putSetLotteryWinner(winner.id).then(() =>{
      const newList = [...listParticipants];
      newList.splice(winner.idx, 1);
      console.log(listParticipants, newList);
      this.setState({
        listParticipants: newList,
        hasWinner: false,
      })
    }).catch(err => {
      console.log('failed to set winner', err);
    });
  }

  handleKeydown = (e) =>{
    // console.log("pencet tombol", e);
    const {isRolling, hasWinner} = this.state;
    if((e.charCode === 13) && (!isRolling) && (hasWinner)){
      this.chooseWinner();
    }else if(e.charCode === 32){
      if(!isRolling){
        this.startLottery();
      } else {
        this.stopLottery();
      }
    }
  }

  render() {

    const {hasWinner, winner, isRolling, listParticipants} = this.state;

    return (
      <div id="bg" className="font-poppins">
        <div>
          {/* <!-- Content --> */}
          <div
            className="dez-coming-soon style-7 wow fadeIn"
            data-wow-duration="0.80s"
            data-wow-delay="0.50s"
          >
            <canvas id="canvas3"></canvas>
            {/* <div style={{height: "500px", overflow: "hidden"}}>
              <img src={EventBG} alt=""/>
            </div> */}
            <div className="video-container-fullscreen">
              <video autoPlay muted loop>
                <source src={VideoBG} type="video/mp4"/>
              </video>
            </div>
            <div className="clearfix dez-coming-bx">
              <div className="posi-center">
                <div
                  className="logo wow fadeInUp mb-0"
                  data-wow-duration="1.5s"
                  data-wow-delay="1.0s"
                >
                  <img src={LogoClient} width="550px" alt="" />
                </div>
                <div
                  className="logo wow fadeInUp mb-0"
                  data-wow-duration="1.5s"
                  data-wow-delay="1.0s"
                >
                  <img src={LogoEvent} width="550px" style={{margin: "-25px 0px -20px 0px"}} alt="" />
                </div>
                {!isRolling && (
                  <>
                    <h2
                      className="dez-title wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay={hasWinner ? "0s" : "1.7s"}
                    >
                      {winner ? winner.name : "Pemenang"}
                    </h2>
                    <p
                      className="wow fadeInUp fs-24px"
                      data-wow-duration="1.5s"
                      data-wow-delay={hasWinner ? "0s" : "1.7s"}
                    >
                      {winner ? winner.agencyTeam : "Agency"}
                    </p>
                  </>
                )}
                {(isRolling && 
                  <div className='mt-1 mb-4'>
                    <span style={{display: "inline-block"}} className="spinner spinner-lg spinner-gold"></span>
                  </div>
                )}
                <div className="countdown count-1">
                  <div
                    className="date wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="1.0s"
                  >
                    {winner?.numSplit?
                      <span className="days time">{winner.numSplit[0]}</span>
                      :
                      <span className="days time" id="output1">00</span>
                    }
                  </div>
                  <div
                    className="date wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="1.3s"
                  >
                    {winner?.numSplit?
                      <span className="hours time">{winner.numSplit[1]}</span>
                      :
                      <span className="hours time" id="output2">00</span>
                    }
                  </div>
                  <div
                    className="date wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="1.6s"
                  >
                    {winner?.numSplit?
                      <span className="mins time">{winner.numSplit[2]}</span>
                      :
                      <span className="mins time" id="output3">00</span>
                    }
                  </div>
                  <div
                    className="date wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="1.9s"
                  >
                    {winner?.numSplit?
                      <span className="secs time">{winner.numSplit[3]}</span>
                      :
                      <span className="secs time" id="output4">00</span>
                    }
                  </div>
                </div>
                {listParticipants.length > 0 && 
                (
                  <div
                    className="dez-coming-btn wow fadeInUp"
                    data-wow-duration="1.5s"
                    data-wow-delay="2.2s"
                  >
                    {!this.state.isRolling && (
                      <button 
                        className="site-button gradient openbtn button-lg radius-md w-200px"
                        onClick={() => this.startLottery()}
                      >
                        <i className='fa fa-heart'/> Round
                      </button>
                    )}
                    {this.state.isRolling && (
                      <button 
                        className="site-button gradient button-lg radius-md w-200px"
                        onClick={() => this.stopLottery()}
                      >
                        <i className='fa fa-stop'/> Stop
                      </button>
                    )}
                    {this.state.hasWinner && (
                      <button 
                        className="site-button button-lg outline radius-md w-200px ms-5"
                        onClick={()=> this.chooseWinner()}
                      >
                        <i className='fa fa-star'/> Winner
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <audio id="audio1" loop>
              <source src={AudioRolling} type="audio/wav"/>
            </audio>
            <audio id="audio2">
              <source src={AudioRing} type="audio/wav"/>
            </audio>
          </div>
          {/* <!-- Content END--> */}
        </div>
        <LotteryScript />
      </div>
    );
  }
}

export default withNotification(Lottery);
