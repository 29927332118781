import React from 'react';
// import * as handTrack from 'handtrackjs';
import { AppSettings } from '../../config/app-settings.js';
import withNotification from '../../components/HOCNotification/HOCNotification.component.js';
import Services from '../../services/service.js';
import UserProfile from '../../services/userProfile';
import {RESPONSE} from '../../config/constants';
import WebCam from './WebCam';
import QRScan from '../registrationScan/QRScan.js';
import ModalPhotoResult from './ModalPhotoResult.js';
//assets
import LoginBG from '../../assets/img/bg/bg-venna-ryan.jpg';
import LogoVinaa from '../../assets/img/logo/logo-vinaa.png';
import LogoClient from '../../assets/img/logo/logo-venna-ryan.png';
import BannerClient from '../../assets/img/logo/banner-venna-ryan.png';
import QRGuide from '../../assets/img/qrcode-guide.gif';

const userProfile = new UserProfile();
const {FAILED, SUCCESS} = RESPONSE;

class Photobooth extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      event: {},
      participant: {},
      loading: false,
      imgSrc: null,
      model: null,
      video: null,
      counter: 5,
      showModalResult: false,
    };
    this.getEventData = this.getEventData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.getData = this.getData.bind(this);
    this.showDataTime = 5000;
    this.webcamRef = React.createRef();
    this.capture = this.capture.bind(this);
    this.toggleModalResult = this.toggleModalResult.bind(this);
    this.uploadPhotobooth = this.uploadPhotobooth.bind(this);
    // this.initHandTrack = this.initHandTrack.bind(this);
    // this.runDetection = this.runDetection.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    // this.initHandTrack();
    this.getEventData();
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  // async initHandTrack() {
  //   const video = document.getElementById('cam-booth');
  //   let videoStatus = await handTrack.startVideo(video);
  //   if (!videoStatus) throw "Start video failed";
  //   const model = await handTrack.load();
  //   handTrack.startVideo(video);
  //   // console.log('model', model);
    
  //   function runDetection() {
  //     // const {model, video} = this.state;
  //     const canvas = document.getElementById("canvas");
  //     const context = canvas.getContext("2d");
          
  //     model.detect(video).then(predictions => {
  //         console.log("Predictions: ", predictions);
  //         model.renderPredictions(predictions, canvas, context, video);
  //         // if (isVideo) {
  //         requestAnimationFrame(runDetection);
  //     });
  //   }
  // }

  getEventData(){
    const params = {
      id: userProfile.getSelectedEvent().value
    };
    Services.getEventDetail(params).then(res =>{
      // console.log(res);
      this.setState({event: res});
    }).catch(error =>{
      console.log('failed to get event detail', error);
    });
  }

  setLoading(status) {
    this.setState({ loading: status });
  }

  getData(code){
    const params = {
      code,
      eventId: this.state.event._id
    };
    Services.getQRCodeData(params).then(res =>{
      // console.log('data qr code', res);
      if(res.status){
        this.setState({
          participant: {...res.data}
        }, () => {
            this.myInterval = setInterval(() => {
              const { counter } = this.state;

              if (counter > 0) {
                  this.setState(({ counter }) => ({
                      counter: counter - 1
                  }))
              }
              if (counter === 0) {
                clearInterval(this.myInterval);
                //TODO take photo
                this.capture();
                this.setState({
                  loading: false,
                  counter: 5
                }, () =>{
                  this.uploadPhotobooth();
                  setTimeout(() =>{
                    this.toggleModalResult();
                    this.setState({
                      participant: {},
                      imgSrc: null
                    });
                  }, this.showDataTime);
                });
              } 
            }, 1000);
          }
        );
      }
      ;
    }).catch(err =>{
      console.log(err);
      this.props.toggleNotification(FAILED, "Gagal scan QR Code. Data tidak ditemukan.")
      this.setLoading(false);
    })
  }

  capture (){
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.setState({imgSrc: imageSrc});
    this.toggleModalResult();
  };

  uploadPhotobooth(){
    const {participant, imgSrc} = this.state;
    const payload = {
      participantId: participant._id,
      file: imgSrc,
    };
    // console.log("payload upload photobooth", payload);
    Services.postPhotobooth(payload).then(res =>{
      // console.log(res);
      this.props.toggleNotification(SUCCESS, "Foto berhasil disimpan");
    }).catch(error =>{
      console.log('failed to upload photo', error);
    });
  }

  toggleModalResult(){
    this.setState({showModalResult: !this.state.showModalResult});
  }

  render() {
    const { participant, loading } = this.state;

    const containerStyle = {
      maxWidth: '800px',
    };

    // const coverStyle = {
    //   background:
    //     'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8))',
    // };

    const qrGuideStyle = {
      borderRadius: '10px',
      background: 'rgba(255, 255, 255, 0.4)',
    };

    const {counter, imgSrc, showModalResult} = this.state;

    return (
      <>
        <div className="login login-v2 fw-bold">
          <div className="login-cover">
            <div
              className="login-cover-img"
              style={{ backgroundImage: `url(${LoginBG})` }}
            ></div>
            <img className="logo-vinaa" src={LogoVinaa} alt="logo-vinaa" />
            <img className="logo-client" src={LogoClient} alt="logo-client" style={{width: "120px"}}/>
            <div className="video-container-fullscreen">
              {loading && (
                <>
                  <WebCam 
                    webcamRef={this.webcamRef}
                  />
                  <div className='photo-box'/>
                </>
              )}
              {/* <canvas id="canvas" className="border qr-video"></canvas> */}
              {!loading && (
                <QRScan
                  getData={this.getData}
                  loading={this.loading}
                  setLoading={this.setLoading}
                />
              )}
            </div>
            {/* <div className="login-cover-bg" style={coverStyle}></div> */}
          </div>
          <div className="login-container text-center" style={containerStyle}>
            {!loading && (
              <>
                <div className='mb-3'>
                  <img src={BannerClient} alt="banner" width="500px"/>
                </div>
                <h3 className='mb-3'>PERLIHATKAN QR CODE ANDA</h3>
                <img src={QRGuide} width="500px" style={qrGuideStyle} alt="qrcode-guide"/>
                <p className='fs-64px'>PHOTOBOOTH</p>
              </>
            )}
            {loading && (
              <div className='fs-48px'>
                <p className='m-0 text-white-50'>Hi, {participant.name}</p>
                <p className='mb-5 text-white-50'>SHOW YOUR BEST POSE</p>
                {/* <p><i className='m-0 fa fa-camera-retro fa-2x text-white-50'/></p> */}
                <p className='m-0 text-white-50' style={{fontSize: "150px"}}><i className='m-0 fa fa-camera-retro text-white-50'/> {counter > 0 ? counter : ''}</p>
              </div>
            )}
          </div>
        </div>
        {showModalResult && (
          <ModalPhotoResult
            isOpen={showModalResult}
            toggle={this.toggleModalResult}
            imgSrc={imgSrc}
            // imgSrc={LoginBG}
          />
        )}
      </>
    );
  }
}

export default withNotification(Photobooth);
