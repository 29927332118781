import React from 'react';
// import { Link } from 'react-router-dom';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown} from 'reactstrap';
import UserProfile from '../../../services/userProfile';
import Services from '../../../services/service';

const userProfile = new UserProfile();

class DropdownEvent extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      options: [],
      selectedEvent: userProfile.getSelectedEvent()
    };
    this.getEventOptions = this.getEventOptions.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  componentDidMount = () => {
    if(userProfile.getUserProfile()){
      this.getEventOptions();
    }
  }

  getEventOptions = () =>{
    const params = {
      clientId : userProfile.getUserProfile().clientId,
    }
    Services.getEventList(params)
    .then(res => {
      const newOptions = res.map(event => ({
        label: event.title,
        value: event._id
      }));
      this.setState({options: newOptions});
      if(newOptions[0] && !this.state.selectedEvent){
        userProfile.setSession('selected-event', JSON.stringify(newOptions[0]));
        this.setState({selectedEvent: newOptions[0]});   
      }
    }).catch(err => console.log('failed get event list', err));
  }

  onChange = (e) =>{
    const {value} = e.target;
    const selectedEvent = this.state.options[value];
    // console.log(value, selectedEvent);
    userProfile.setSession('selected-event', JSON.stringify(selectedEvent));
    this.setState({selectedEvent: selectedEvent});
    window.location.reload(false);
  }

  render() {
    return (
      <UncontrolledButtonDropdown direction="down" className="me-2"> 
        <button className="btn btn-default">{this.state.selectedEvent? this.state.selectedEvent.label : 'Pilih Event'}</button>
        <DropdownToggle color="default" className="dropdown-toggle">
          <i className="fa fa-caret-down"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
          {this.state.options.map((event, idx) =>(
            <DropdownItem key={idx} onClick={this.onChange} value={idx}>{event.label}</DropdownItem>  
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
};

export default DropdownEvent;
