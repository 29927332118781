import React from 'react';
import { Link } from 'react-router-dom';

class ButtonScanRegistration extends React.Component {

  render() {
    return (
      <Link to="/scan-qr" target="_blank" rel="noopener noreferrer" className="btn btn-danger me-2">
        <i className="fa fa-qrcode"></i> Registrasi Scan QR
      </Link>
    );
  }
};

export default ButtonScanRegistration;
