import React from 'react';
import { AppSettings } from '../../config/app-settings.js';
import withNotification from '../../components/HOCNotification/HOCNotification.component.js';
import Services from '../../services/service.js';
import UserProfile from '../../services/userProfile';
import {RESPONSE} from '../../config/constants';
import QRScan from './QRScan';
//assets
import LoginBG from '../../assets/img/sinarmas/BG.jpg';
import LogoVinaa from '../../assets/img/sinarmas/logo-sinarmas.png';
import LogoClient from '../../assets/img/sinarmas/logo-forever.png';
import BannerClient from '../../assets/img/sinarmas/logo-great-stars-with-date.png';
import WelcomeSign from '../../assets/img/sinarmas/logo-great-stars-with-date.png';
import VideoBG from '../../assets/video/guest-welcome-sinarmas.mp4';
import QRGuide from '../../assets/img/qrcode-guide-2.gif';
import "./RegistrationScan.css";

const userProfile = new UserProfile();
const {FAILED} = RESPONSE;

class RegistrationScan extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      event: {},
      participant: {},
      loading: false
    };
    this.getEventData = this.getEventData.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.getData = this.getData.bind(this);
    this.showDataTime = 7000;
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    this.getEventData();
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  getEventData(){
    const params = {
      id: userProfile.getSelectedEvent().value
    };
    Services.getEventDetail(params).then(res =>{
      // console.log(res);
      this.setState({event: res});
    }).catch(error =>{
      console.log('failed to get event detail', error);
    });
  }

  setLoading(status){
    this.setState({loading: status});
  }

  getData(code){
    const params = {
      code,
      eventId: this.state.event._id
    };
    Services.getQRCodeData(params).then(res =>{
      console.log('data qr code', res);
      if(res.status){
        this.setState({
          participant: {...res.data},
          // showVideo: true
        }, () => {
            setTimeout(() =>{
              this.setState({
                participant: {},
                // showVideo: false,
                loading: false,
              });
            }, this.showDataTime);
          }
        );
      }
      ;
    }).catch(err =>{
      console.log(err);
      this.props.toggleNotification(FAILED, "Gagal scan QR Code. Data tidak ditemukan.")
      this.setLoading(false);
    })
  }

  render() {
    const {participant, loading, event} = this.state;

    const containerStyle = {
      maxWidth: "800px",
      // display: "none"
    }
    
    const coverStyle ={
      background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5))"
    }

    // const qrGuideStyle = {
    //   borderRadius: "10px",
    //   background: "rgba(255, 255, 255, 0.4)",
    //   width: "500px",
    //   height: "500px"
    // }

    const qrResultStyle = {
      borderRadius: "10px",
      background: "rgba(0, 0, 0, 0.4)"
    }

    return (
      <>
        <div className="login login-v2 fw-bold">
          <div className="login-cover">
            <div
              className="login-cover-img"
              style={{ backgroundImage: `url(${LoginBG})` }}
            ></div>
            <img
              className="logo-vinaa"
              src={LogoVinaa}
              alt="logo-vinaa"
              style={{width: "400px"}}
            />
            <img
              className="logo-client"
              src={LogoClient}
              alt="logo-client"
              style={{width: "200px"}}
            />
            <div className="video-container-fullscreen">
              {!loading && (
                <QRScan 
                  getData={this.getData}
                  loading={this.loading}
                  setLoading={this.setLoading}
                />
              )}
              {loading && (
                <video autoPlay muted loop>
                  <source src={VideoBG} type="video/mp4"/>
                </video>
              )}
            </div>
            <div className="login-cover-bg" style={coverStyle}></div>
          </div>
          <div className='login-container text-center' style={containerStyle}>
            {!loading && (
              <>
                <div style={{marginBottom: "-5px", marginLeft: "-5px"}}>
                  <img src={BannerClient} alt="banner" width="700px"/>
                </div>
                <h3 className='mb-3'>PERLIHATKAN QR CODE ANDA</h3>
                <div className='qrscan-area' id="qr-area">
                  <img src={QRGuide} width="500px" alt="qrcode-guide"/>
                </div>
              </>
            )}
            {loading && (
              <>
                <div className='mb-3'>
                  <img src={WelcomeSign} alt="welcome-sign" width="800px" style={{marginBottom: "-25px"}}/>
                </div>
                <div className='p-2' style={qrResultStyle}>
                  {event?.fieldFrontScanner?.map((field, idx) => (
                    <React.Fragment key={idx}>
                      {field.key !== 'title' &&
                        <>
                          <h2 className='mb-0' style={{color: "#C7B053"}}>{field.label}</h2>
                          <p className='mb-3 fs-36px'>
                            {participant[field.key] ? (
                              (field.key === "name") ?
                                (<span className='fs-48px'>{`${participant[field.key]}`}</span>) 
                                : participant[field.key]
                            ) : (
                              <i className="fas fa-spinner fa-pulse" />
                            )}
                          </p>
                        </>
                      }
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withNotification(RegistrationScan);
