import Services from './service';

export default class UserProfile {

  // setUsername = (username) => {
  //   this.setSession('username', username);
  // }

  setUserProfile = async(token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return await Services.getUserProfile({headers})
      .then(res => {
        const userProfile = JSON.stringify(res);
        this.setSession('user-profile', userProfile);
        this.setSession('username', res.username);
        return res;
      })
      .catch(err => {console.log(err)});
  }

  getUsername = () => {
    const username = localStorage.getItem('username');
    // const usernameDecode = Buffer.from(username, 'base64').toString();
    return username;
  }

  getUserProfile = () => {
    const userProfile = localStorage.getItem('user-profile');
    // const userProfiledecode = Buffer.from(userProfile, 'base64').toString();
    return JSON.parse(userProfile);
  }

  getSelectedEvent = () => {
    const selectedEvent = localStorage.getItem('selected-event');
    if(selectedEvent){
      return JSON.parse(selectedEvent);
    }
    return null;
  }

  setSession = (key, val) =>{
    // const userProfileEncode = Buffer.from(val).toString('base64')
    localStorage.setItem(key, val);
  }

  getSession = (key) =>{
    localStorage.getItem(key);
  }

  isFirstLogin = () => {
    try {
      const { status } = this.getUserProfile();
      if(status === 0) {
        return true;
      }else{
        return false;
      }
    } catch (error) {
      window.location.replace('/');
    }
  }
}
