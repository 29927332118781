import React from 'react';
import { Modal, ModalBody} from 'reactstrap';

const ModalPhotoResult = ({
  isOpen,
  toggle,
  imgSrc
}) => {

  return (
    <Modal isOpen={isOpen} toggle={() => toggle(null)} style={{maxWidth: "90%"}} centered>
      <ModalBody>
        <img
          className='img-fluid'
          src={imgSrc}
          alt="Photobooth Result"
        />
        {/* <p className='m-0 fs-28px text-center'>~ TERIMA KASIH ~</p> */}
      </ModalBody>
    </Modal>
  );
};

export default ModalPhotoResult;
