export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://vinaa-services-6fnfw.ondigitalocean.app';
export const TIMEOUT = process.env.REACT_APP_API_TIMEOUT ? process.env.REACT_APP_API_TIMEOUT : 30000;
export const COPYRIGHT = "\u00A9 Vinaa All Right Reserved 2024";


export const RESPONSE = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  INVALID: 'INVALID',
  ADDED: 'ADDED',
  INFO: 'INFO',
};

export const MODALACTION = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  VIEW: 'VIEW'
};

export const TYPE_RESEND = {
  WHATSAPP: 1,
  EMAIL: 2
}