import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Services from '../../services/service';
import { RESPONSE, API_URL } from '../../config/constants';
import { Panel, PanelBody } from '../../components/panel/panel';
import { saveAs } from 'file-saver';

const { FAILED } = RESPONSE;

const ModalShowQR = ({
  isOpen,
  toggle,
  toggleNotification,
  selectedParticipant = null,
}) => {
  
  const [dataDetail, setDataDetail] = useState({})
  const [loadingModal, setLoadingModal] = useState(false);


  useEffect(() => {
    const getDetail = () => {
      Services.getParticipantDetail(selectedParticipant)
      .then(async (res) =>{
        setDataDetail(res);
        setLoadingModal(false);
      }).catch(error =>{
        setLoadingModal(false);
        console.log('error get detail', error);
        if (error.response.data && error.response.data.error) {
          toggleNotification(FAILED, error.response.data.error);
        } else {
          toggleNotification(FAILED, error.response.statusCode);
        }
      });
    }

    if(selectedParticipant){
      setLoadingModal(true);
      getDetail();
    }
  }, [selectedParticipant, toggleNotification]);

  const downloadImage = () => {
    saveAs(`${dataDetail.qrCode}`, `QR Code ${dataDetail.name}.png`);
  }

  return (
    <Modal isOpen={isOpen} toggle={() => toggle(null)}>
      <ModalHeader toggle={() => toggle(null)}>Verifikasi Peserta</ModalHeader>
      <ModalBody>
        <Panel className={loadingModal ? 'panel-loading' : ''}>
          <PanelBody className="p-0">
            {loadingModal && (
              <div className="panel-loader">
                <span className="spinner spinner-sm"></span>
              </div>
            )}
            <div className="mb-2">
              <label>
                <strong>Area</strong>
              </label>
              <br />
              {dataDetail?.institution}
            </div>
            <div className="mb-2">
              <label>
                <strong>Nama</strong>
              </label>
              <br />
              {dataDetail?.name}
            </div>
            <div className="mb-2">
              <label>
                <strong>Buyer Name</strong>
              </label>
              <br />
              {dataDetail?.agencyTeam}
            </div>
            <div className="mb-2">
              <label>
                <strong>Area</strong>
              </label>
              <br />
              {dataDetail?.title}
            </div>
            <label>
              <strong>QR Code</strong>
            </label>
            <div className="text-center">
              <br />
              <img src={`${dataDetail.qrCode}`} alt="QR Code" width={450} />
            </div>
          </PanelBody>
        </Panel>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-white btn-sm" onClick={() => toggle()}>
          <i className="fa fa-times"></i> Tutup
        </button>
        <button
          className="btn btn-green btn-sm"
          onClick={downloadImage}
        >
          <i className="fa fa-download"/> Download QR Code
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalShowQR;
