import { createTheme } from '@material-ui/core/styles';

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      // MuiTableCell: {
      //   root: {
      //     borderColor: '#d3d3d3'
      //   },
      //   head: {
      //     backgroundColor: '#d3d3d3',
      //     // color: 'white'
      //     // borderRight: [[1, 'solid', '#d3d3d3']]
      //     // '&:not(:last-child)': {
      //     //   borderRight: [[1, 'solid', '#c0c0c0']],
      //     // },
      //   },
      // },
      MUIDataTableHeadCell:{
        fixedHeader:{
          // backgroundColor: '#d3d3d3',
          backgroundColor: '#e35c58',
        }
      },
      MUIDataTable: {
        responsiveBase: {
          minHeight: '300px',
        },
      },
      // MuiButton:{
      //   root: {
      //     color: "white"
      //   }
      // }
    },
  });

export const tableOptions = {
  // responsive: "simple",
  filterType: 'dropdown',
  selectableRows: 'none',
  tableBodyMaxHeight: (window.innerHeight - 250).toString() + 'px',
  setTableProps: () => {
    return {
      // padding: "none",
      // material ui v4 only
      size: "small"
    };
  }
}