import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import LoginBG from '../../assets/img/bg/bg-home.jpg';
import LogoVinaa from '../../assets/img/logo/logo-vinaa-only.png';
import withNotification from '../../components/HOCNotification/HOCNotification.component.js';
import Services from '../../services/service.js';
import Auth from '../../services/auth.js';
import { RESPONSE, COPYRIGHT } from '../../config/constants.js';

const { SUCCESS, FAILED } = RESPONSE;
const auth = new Auth();
class Login extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      passwordShown: false,
      formErrors: [],
      submitLoading: false,
      authenticated: auth.isAuthenticated(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.hasError = this.hasError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePasswordShown = this.togglePasswordShown.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const state = { [name]: value };
    this.setState(state);
  };

  validateForm() {
		let errors  = [];
		if(this.state.username === '') {
			errors.push('username')
		}
		if(this.state.password === '') {
			errors.push('password');
		}

		this.setState({
			formErrors: errors
		});

    return errors.length === 0;
	}

  hasError(key) {
		return this.state.formErrors.indexOf(key) !== -1;
	}

  handleSubmit(event) {
    event.preventDefault();
    const validate = this.validateForm();
    if(validate){
      this.setState({submitLoading: true});
      const { username, password } = this.state;
  
      const payload = { username, password };

      Services.postLogin(payload)
			.then(async res => {
				if(res){
          this.props.toggleNotification(SUCCESS, 'Login Berhasil');
          auth.setSession(res);
				}
			})
			.catch(err => {
        this.setState({submitLoading: false});
        console.log('error', err);
				this.props.toggleNotification(FAILED, 'Login Gagal');
			})
    }
  }

  togglePasswordShown () {
    this.setState({ passwordShown: !this.state.passwordShown});
  }

  render() {
    if (this.state.authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div className="login login-v2 fw-bold">
          <div className="login-cover">
            <div
              className="login-cover-img"
              style={{ backgroundImage: `url(${LoginBG})` }}
            ></div>
            <div className="login-cover-bg"></div>
          </div>

          <div className="login-container">
            <div className="login-header">
              <div className="brand">
                <div className="d-flex align-items-center">
                  <img
                    className="me-2"
                    src={LogoVinaa}
                    alt="logo-vinaa"
                    width={40}
                  />{' '}
                  <b>Vinaa</b> Admin
                </div>
                <small>Your Virtual Invitation and Attention</small>
              </div>
              <div className="icon">
                <i className="fa fa-lock"></i>
              </div>
            </div>
            <div className="login-content">
              <form onSubmit={this.handleSubmit}>
                <div className='mb-20px'>
                  <div className={`form-floating ${this.hasError("username") ? 'is-invalid': ''}`}>
                    <input
                      type="text"
                      className={`form-control fs-13px h-45px border-0 ${this.hasError("username") ? 'is-invalid': ''}`}
                      placeholder="Username"
                      name="username"
                      onChange={this.handleChange}
                    />
                    <label
                      htmlFor="username"
                      className="d-flex align-items-center text-gray-600 fs-13px"
                    >
                      Username
                    </label>
                  </div>
                  <div className="invalid-feedback">Username harus diisi.</div>
                </div>
                <div className='mb-20px'>
                  <div className={`form-floating pass-wrapper ${this.hasError("password") ? 'is-invalid': ''}`}>
                    <input
                      type={this.state.passwordShown ? "text" : "password"} 
                      className={`form-control fs-13px h-45px border-0 ${this.hasError("password") ? 'is-invalid': ''}`}
                      placeholder="Password"
                      name="password"
                      onChange={this.handleChange}
                    />
                    <i className={`text-gray-600 fs-16px ${this.state.passwordShown ? "fa fa-eye": "fa fa-eye-slash"}`} onClick={this.togglePasswordShown}/>
                    <label
                      htmlFor="password"
                      className="d-flex align-items-center text-gray-600 fs-13px"
                    >
                      Password
                    </label>
                  </div>
                  <div className="invalid-feedback">Password harus diisi.</div>
                </div>
                {/* <div className="form-check mb-20px">
									<input className="form-check-input border-0" type="checkbox" value="1"  id="rememberMe" />
									<label className="form-check-label fs-13px text-gray-500" htmlFor="rememberMe">
										Remember Me
									</label>
								</div> */}
                <div className="mb-20px">
                  <button
                    type="submit"
                    className="btn btn-success d-block w-100 h-45px btn-lg btn-danger"
                  >
                  {(this.state.submitLoading) ?
										<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										:
										'Sign In'
									}
                  </button>
                </div>
                {/* <div className="text-gray-500">
									Not a member yet? Click <Link to="/user/register-v3">here</Link> to register.
								</div> */}
                <hr className="bg-gray-600 opacity-2" />
                <p className="text-center text-gray-600">{COPYRIGHT}</p>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withNotification(Login);
