import api from '../config/api';

const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : {'Content-Type': 'application/json'};

const API = new api({headers: header});
const APIUpload = new api({headers: {...header, 'Content-Type': 'multipart/form-data'}});

//AUTH API
const AuthAPI = {
  postLogin: (payload) => API.post('auth/login', payload),
  // postForgotPassword: (payload) => API.post('auth/forgot-password', payload),
  // postVerifyPassword:(payload) => API.post('/auth/forgot-password/verify', payload),
  // getGetMenu: (payload) => API.get('/menu-access', payload),
};

//USER API
const UserAPI = {
  getUserProfile: (config) => API.get('user/profile', config),
  // getUserList: () => API.get('user/list'),
  // getUserListByRole: (roleId) => API.get(`user/list-by-role?roleId=${roleId}`),
  // getUserDetail: (payload) => API.get(`user/detail?userId=${payload}`),
  // deleteUser: (payload) => API.delete(`/user/delete?userId=${payload}`),
  // postUser: (payload) => API.post('user/create', payload),
  // updateUser: (payload, param) => API.put('user/update', payload, param),
  // postConfirmAction: (payload) => API.post('user/verification-password', payload)
};

//PARTICIPANT API
const ParticipantAPI = {
  postParticipant: (payload) => APIUpload.post(`registration`, payload),
  getParticipantList: (params) => API.get(`participants`, {params}),
  getParticipantDetail: (id) => API.get(`participants/${id}`),
  putParticipant: (id, payload) => API.put(`participants/${id}`, payload),
  deleteParticipant: (id) => API.delete(`participants/${id}`),
  getParticipantFile: (params) => API.get(`participants/file`, {params, responseType: 'blob'}),
  verifyParticipant: (params) => API.put(`participants/verify`, {}, {params}),
  getQRCodeData: (params) => API.get(`qr-code/read`, {params}),
  postResendQRCode: (payload, params) => API.post(`participants/resend-qrcode`, payload, {params}),
};

//PARAMETER API
const ParamAPI = {
  getList: (payload) => API.get('parameter', {params: payload}),
  // getAllParamList: (payload) => API.get('parameter-list', {params: payload}),
  // getParamDetail: (payload) => API.get(`parameter-detail?paramId=${payload}`),
  // postParameter: (payload) => API.post('parameter-create', payload),
  // updateParamValue: (payload, param) => API.put(`parameter-update`, payload, param),
};

const EventAPI = {
  getEventList: (params) => API.get('event/list', {params}),
  getEventDetail: (params) => API.get('event/getById', {params}),
}

const PhotoboothAPI = {
  postPhotobooth: (payload) => API.post(`photobooth/upload`, payload),
}

const LotteryAPI = {
  getListParticipantLottery: (params) => API.get(`participants-present`, {params}),
  putSetLotteryWinner: (participantId) => API.put(`participants-set-winner/${participantId}`),
}

const Services = {
  ...AuthAPI,
  ...UserAPI,
  ...ParticipantAPI,
  ...ParamAPI,
  ...EventAPI,
  ...PhotoboothAPI,
  ...LotteryAPI,
};

export default Services;
