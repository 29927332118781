import React from 'react';
import { Redirect } from 'react-router';

import Auth from '../services/auth';
// import Error403 from '../pages/error/error-403';

const auth = new Auth();
const isAuthenticated = auth.isAuthenticated();

const AuthGuard = ChildComponent => props => {
  
  // const checkAuthorized = () =>{
  //   let {path} = props.match;
  //   const pathArray = path.split('/');
  //   if(pathArray.length > 3){
  //     path = pathArray.slice(0,3).join("/");
  //   }
  //   const menuAccess = localStorage.getItem("menu-access");
  //   const isAuthorized = menuAccess.includes(path) || (path === "/user/profile");
  //   return isAuthorized;
  // } 

  return (
    // isAuthenticated ? checkAuthorized() ? <ChildComponent {...props}/> : <Error403/> : <Redirect to='/login'/> 
    isAuthenticated ? <ChildComponent {...props}/> : <Redirect to='/login'/> 
  );
}

export default AuthGuard;