import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Services from '../../services/service';
import { RESPONSE } from '../../config/constants';
import { Panel, PanelBody } from '../../components/panel/panel';

const { SUCCESS, FAILED } = RESPONSE;

const ModalVerify = ({
  isOpen,
  toggle,
  toggleNotification,
  setLoading,
  selectedParticipant = null,
  imgUrl,
}) => {
  const [loadingModal, setLoadingModal] = useState(false);

  const verifyParticipant = () => {
    setLoadingModal(true);
    const params = {id: selectedParticipant._id};
    Services.verifyParticipant(params)
    .then(res => {
      console.log('success verify', res);
      toggle();
      toggleNotification(SUCCESS, res.msg);
      setLoading(true);
    })
    .catch(err => {
      console.log('failed to verify participant', err);
      toggleNotification(FAILED, err.response.data.error);
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={() => toggle(null)}>
      <ModalHeader toggle={() => toggle(null)}>Verifikasi Peserta</ModalHeader>
      <ModalBody>
        <Panel className={loadingModal ? 'panel-loading' : ''}>
          <PanelBody className="p-0">
            {loadingModal && (
              <div className="panel-loader">
                <span className="spinner spinner-sm"></span>
              </div>
            )}
            <div className="mb-2">
              <label>
                <strong>Email</strong>
              </label>
              <br />
              {selectedParticipant?.email}
            </div>
            <div className="mb-2">
              <label>
                <strong>Nama</strong>
              </label>
              <br />
              {selectedParticipant?.name}
            </div>
            <div className="mb-2">
              <label>
                <strong>Nama Orang Tua</strong>
              </label>
              <br />
              {selectedParticipant?.parentName}
            </div>
            <label>
              <strong>Bukti Bayar</strong>
            </label>
            <div className="text-center">
              <br/>
              <img 
                src={imgUrl} 
                alt='Bukti Bayar' 
                width={450}
              />
            </div>
          </PanelBody>
        </Panel>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-white btn-sm"
          onClick={() => toggle()}
        >
          <i className="fa fa-times"></i> Tutup
        </button>
        <button
          className="btn btn-green btn-sm"
          onClick={verifyParticipant}
        >
          <i className="fa fa-check"></i> Verifikasi
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalVerify;
