import React from 'react';
import QrReader from 'react-qr-scanner';

const QRScan = ({getData, loading, setLoading}) => {
  const state = {
    delay: 100,
    result: 'No result',
  };

  const handleScan = (data) => {
    if(data?.text && !loading){
      //TODO change css
      document.getElementById("qr-area").style.setProperty("--c", "#5bff57");
      setLoading(true);
      // console.log("data", data.text);
      getData(data.text);
    }else{
      document.getElementById("qr-area").style.setProperty("--c", "#ff5b57");
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    transform: "translate(-50%, -50%) scaleX(-1)"
  }

  return (
    <>
      <QrReader
        // className="qr-video"
        delay={state.delay}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
      />
    </>
  );
};

export default QRScan;
