import React from 'react';
import useScript from '../../../utils/hooks/useScript';

const LotteryScript = () => {

  return (
    <>
      {useScript('./assets/js/jquery.min.js', 'body', false)}
      {useScript('./assets/plugins/colorful-snakes/colorful-snakes.js', 'body', false)}
      {useScript('./assets/plugins/colorful-snakes/colorful-snakes.app.js', 'body', false)}
      {useScript('./assets/js/wow.js', 'body', false)}
      {useScript('./assets/js/custom.js', 'body', false)}
    </>
  );
};

export default LotteryScript;