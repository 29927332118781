import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import routes from './../../config/app-route.jsx';
import { AppSettings } from './../../config/app-settings.js';
import Error404 from '../../pages/error/error-404.js';

function setTitle(path, routeArray) {
  var appTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      appTitle = 'Vinaa Platform | ' + routeArray[i].title;
    }
  }
  document.title = (appTitle) ? appTitle : 'Vinaa Platform | Admin';
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes);
  }
  UNSAFE_componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes);
    });
  }

  render() {
    return (
      <AppSettings.Consumer>
        {({ appContentClass }) => (
          <div className={'app-content ' + appContentClass}>
            <Switch>
							{routes.map((route, index) => (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									component={route.component}
								/>
							))}
							<Route component={Error404}/>
						</Switch>
          </div>
        )
        }
      </AppSettings.Consumer>
    )
  }
}

export default withRouter(Content);
