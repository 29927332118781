import React from 'react';
import Webcam from 'react-webcam';

const WebCam = ({webcamRef}) => {
  // const webcamRef = React.useRef(null);
  // const [imgSrc, setImgSrc] = React.useState(null);

  // const capture = React.useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setImgSrc(imageSrc);
  // }, [webcamRef, setImgSrc]);

  const previewStyle = {
    transform: "translate(-50%, -50%)"
  }

  // const videoConstraints = {
  //   facingMode: "user"
  // };

  return (
    <Webcam
      // id="cam-booth"
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      mirrored
      style={previewStyle}
      // videoConstraints={videoConstraints}
    />
  );
};

export default WebCam;
