import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Panel, PanelBody } from '../../components/panel/panel';
import withNotification from '../../components/HOCNotification/HOCNotification.component';
import Services from '../../services/service';
import { getMuiTheme, tableOptions } from '../../utils/MuiTableTheme.js';
import { MODALACTION, RESPONSE, TYPE_RESEND } from '../../config/constants';
import ModalAddParticipant from './ModalAddParticipant';
import ModalVerify from './ModalVerify';
import UserProfile from '../../services/userProfile';
import ModalShowQR from './ModalShowQR';

const { CREATE, EDIT, DELETE } = MODALACTION;
const { SUCCESS, FAILED } = RESPONSE;
const { 
  // WHATSAPP, 
  EMAIL 
} = TYPE_RESEND;

const ParticipantList = ({toggleNotification}) => {
  const [eventId, setEventId] = useState(new UserProfile().getSelectedEvent());
  
  const [state, setState] = useState({
    modalAdd: false,
    actionModal: '',
    selectedData: null,
    selectedId: '',
    loadingModal: true,
  });

  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalQR, setShowModalQR] = useState(false);
  const [showModalVerify,setShowModalVerify] = useState(false);
  const [alert, setAlert] = useState(null);

  // Get User Data
  const [dataUser, setDataUser] = useState([]);
  useEffect(() => {
    const getData = () => {
      const params = eventId ? {
        eventId: eventId.value
      } : {};

      Services.getParticipantList(params)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setDataUser(res);
        })
        .catch((err) => console.log(err));
    };
    if (loading) {
      getData();
    }
  }, [loading, eventId]);
  
  //Get Event Detail
  const [eventDetail, setEventDetail] = useState(null);
  useEffect(() => {
    const getEventDetail = () => {
      const params = eventId ? {
        id: eventId.value
      } : {};

      Services.getEventDetail(params)
        .then((res) => {
          // console.log("eventdetail", res);
          setEventDetail(res);
        })
        .catch((err) => console.log("failed to get event detail", err));
    };

    getEventDetail();
  }, [eventId]);

  // Get User Detail by Id
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const handleDelete = (id) => {
    Services.deleteParticipant(id)
      .then((res) => {
        setLoading(true);
        setAlert(null);
        toggleNotification(SUCCESS, res.message);
      })
      .catch((err) => {
        console.log(err);
        setAlert(null);
        toggleNotification(FAILED, err.response.data.error);
      });
  };

  const renderDeleteDialog = (id) => {
    return (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Hapus"
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="default"
        cancelBtnText="Batal"
        title="Apakah anda yakin untuk menghapus data ini?"
        onConfirm={() => handleDelete(id)}
        onCancel={() => setAlert(null)}
      ></SweetAlert>
    );
  };

  const toggleModalAdd = (value) =>{
    setSelectedParticipant(value ? value: null);
    setShowModalAdd(!showModalAdd);
  };

  //Start Modal Method
  const toggleModalQR = (value) =>{
    setSelectedParticipant(value ? value: null);
    setShowModalQR(!showModalQR);
  };

  const renderTableUser = () => {
    const columns = [
      {
        name: "no",
        label: "No",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => tableMeta.rowIndex + 1
        }
      }
    ];

    if(eventDetail.fieldDatatable?.length > 0){
      for(const field of eventDetail.fieldDatatable){
        // console.log(field);
        columns.push({
          name: field.key,
          label: field.label,
          options: {
            filter: true,
            sort: true,
          }
        });
      }
    }

    columns.push(
      {
        name: "isVerified",
        label: "Verifikasi",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            if(value){
              return (<span className="badge bg-success fs-12px">SUDAH</span>);
            }else{
              return (<span className="badge bg-danger fs-12px">BELUM</span>);
            }
          }
        },
      },
      {
        name: "isScanned",
        label: "Kehadiran",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            if(value){
              return (<span className="badge bg-success fs-12px">SUDAH HADIR</span>);
            }else{
              return (<span className="badge bg-danger fs-12px">BELUM HADIR</span>);
            }
          }
        },
      },
      {
        name: "countingScanned",
        label: "Jumlah Scan",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value ? value : 0
          }
        },
      },
      {
        name: "_id",
        label: "Aksi",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
          customBodyRender: (value, tableMeta) => {
            return (
              <UncontrolledButtonDropdown direction="down" className="me-2 mb-1">
                <DropdownToggle color="primary" className="dropdown-toggle btn-sm">AKSI <i className="fa fa-caret-down"></i></DropdownToggle>
                <DropdownMenu>
                  {dataUser[tableMeta.rowIndex].isVerified ? 
                    (<>
                      <DropdownItem
                        onClick={() => toggleModalQR(value)}
                        className="text-info"
                        ><i className='fa fa-eye'/> LIHAT QRCODE
                      </DropdownItem>
                      {/* <DropdownItem
                        onClick={() => resendQRCode(value, WHATSAPP)}
                        className="text-success"
                        ><i className='fab fa-whatsapp'/> RESEND QRCODE VIA WHATSAPP
                      </DropdownItem> */}
                      {/* <DropdownItem
                        onClick={() => resendQRCode(value, EMAIL)}
                        className="text-primary"
                        ><i className='fas fa-envelope'/> RESEND QRCODE VIA EMAIL
                      </DropdownItem> */}
                    </>) 
                    : 
                    (
                    <DropdownItem
                      onClick={() => toggleModalVerify(dataUser[tableMeta.rowIndex])}
                      className="text-success"
                      ><i className='fa fa-check-to-slot'/> VERIFIKASI
                    </DropdownItem>
                    )
                  }
                  <DropdownItem
                    onClick={() => toggleModalAdd(value)}
                    className="text-warning"
                  ><i className='fa fa-user-edit'/> {EDIT}</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem
                    onClick={() => setAlert(renderDeleteDialog(value))}
                    className="text-danger"
                  ><i className='fa fa-trash'/> {DELETE}</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            );
          }
        }
      }
    );

    const options = {
      ...tableOptions,
      rowsPerPageOptions: [10,25,50,100],
      customToolbar: () => (
        <button 
          className="btn btn-sm btn-primary" 
          onClick={()=>toggleModalAdd(null)}
        ><i className='fa fa-plus'/> TAMBAH
        </button>
      ),
    };

    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Tabel List Peserta"}
          data={dataUser}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    )
  };

  const resendQRCode = (id , type) =>{
    
    const params = {
      id, 
      type,
      eventId: eventId.value,
    };
    // console.log(params);
    Services.postResendQRCode({}, params)
    .then(res => {
      console.log(res);
      toggleNotification(SUCCESS, res.message);
    })
    .catch(err => {
      console.log(err);
      toggleNotification(FAILED, "Gagal mengirim ulang QR Code");
    });
  }

  const toggleModalVerify = (selectedData = null) => {
    if(selectedData){
      getImgUrl(selectedData);
    }else{
      setImgUrl('');
    }
    setShowModalVerify(!showModalVerify);
    setState(prevState => ({...prevState, selectedData}));
  }

  const getImgUrl = async (selectedData) => {
    const params = {
      filename: selectedData.paymentProof,
      field: 'paymentProof',
    }
    await Services.getParticipantFile(params)
    .then(res => {
        const imgUrl = window.URL.createObjectURL(new Blob([res]));
        setImgUrl(imgUrl);
      }
    ).catch(err => console.log('failed to get img', err));
  }

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/table/data">Home</Link>
        </li>
        <li className="breadcrumb-item active">List Peserta</li>
      </ol>
      <h1 className="page-header">List Peserta</h1>
      <Panel className={loading ? 'panel-loading':''}>
        <PanelBody className="p-0">
          {(loading && 
            <div className="panel-loader">
              <span className="spinner spinner-sm"></span>
            </div>
          )}
          {eventDetail && renderTableUser()}
        </PanelBody>
      </Panel>
      {showModalAdd && 
        <ModalAddParticipant
          isOpen={showModalAdd}
          toggle={toggleModalAdd}
          toggleNotification={toggleNotification}
          setLoading={setLoading}
          selectedParticipant={selectedParticipant}
          modalAction={selectedParticipant ? EDIT : CREATE}
        />
      }
      {showModalVerify && 
        <ModalVerify
          isOpen={showModalVerify}
          toggle={toggleModalVerify}
          toggleNotification={toggleNotification}
          setLoading={setLoading}
          selectedParticipant={state.selectedData}
          imgUrl={imgUrl}
        />
      }
      {showModalQR && 
        <ModalShowQR
          isOpen={showModalQR}
          toggle={toggleModalQR}
          toggleNotification={toggleNotification}
          selectedParticipant={selectedParticipant}
        />
      }
      {alert}
    </div>
  );
};

export default withNotification(ParticipantList);
