import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import withNotification from '../../components/HOCNotification/HOCNotification.component';
import UserProfile from '../../services/userProfile';
import Services from '../../services/service';

const userProfile = new UserProfile();

const Dashboard = ({toggleNotification}) => {
  const [eventId, setEventId] = useState(userProfile.getSelectedEvent());
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState({
    total: 0,
    verified: 0,
    scanned: 0,
  });

  useEffect(() => {
    if(!eventId){
      setTimeout(() => {
        // console.log(userProfile.getSelectedEvent());
        setEventId(userProfile.getSelectedEvent());
      }, 100);
    }
  },[eventId])

  useEffect(() => {
    const getData = () => {
      const params = eventId ? {
        eventId: eventId.value 
      } : {};

      Services.getParticipantList(params)
        .then((res) => {
          let verified = 0;
          let scanned = 0;
          res.forEach(participant =>{
            if(participant.isVerified) verified++;
            if(participant.isScanned) scanned++;
          });
          setLoading(false);
          setState({
            total: res.length,
            verified: verified,
            scanned: scanned
          })
        })
        .catch((err) => console.log(err));
    };
    if (loading && eventId) {
      getData();
    }
  }, [loading, eventId]);

  return (
    <div>
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Dashboard</li>
      </ol>
      <h1 className="page-header">
        Dashboard
      </h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-blue">
            <div className="stats-icon">
              <i className="fa fa-users fa-fw"></i>
            </div>
            <div className="stats-content">
              <div className="stats-title">TOTAL PESERTA</div>
              <div className="stats-number">
                {loading ? (
                  <i className="fas fa-spinner fa-pulse" />
                ) : state.total}
              </div>
              {/* <div className="stats-progress progress">
                <div
                  className="progress-bar"
                  style={{ width: '70.1%' }}
                ></div>
              </div>
              <div className="stats-desc">Better than last week (70.1%)</div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-warning">
            <div className="stats-icon">
              <i className="fa fa-user-check fa-fw"></i>
            </div>
            <div className="stats-content">
              <div className="stats-title">SUDAH DIVERIFIKASI</div>
              <div className="stats-number">
                {loading ? (
                  <i className="fas fa-spinner fa-pulse" />
                ) : (`${state.verified} / ${state.total}`)}
              </div>
              {/* <div className="stats-progress progress">
                <div
                  className="progress-bar"
                  style={{ width: '40.5%' }}
                ></div>
              </div>
              <div className="stats-desc">Better than last week (40.5%)</div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-teal">
            <div className="stats-icon">
              <i className="fa fa-user-tie fa-fw"></i>
            </div>
            <div className="stats-content">
              <div className="stats-title">SUDAH HADIR</div>
              <div className="stats-number">
                {loading ? (
                  <i className="fas fa-spinner fa-pulse" />
                ) : (`${state.scanned} / ${state.total}`)}
              </div>
              {/* <div className="stats-progress progress">
                <div
                  className="progress-bar"
                  style={{ width: '76.3%' }}
                ></div>
              </div>
              <div className="stats-desc">Better than last week (76.3%)</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNotification(Dashboard);
