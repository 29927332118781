import React from 'react';
import { Redirect } from 'react-router';
import Auth from '../services/auth';
import AuthGuard from './AuthGuard';
import Login from '../pages/login/Login';
import Dashboard from '../pages/dashboard/Dashboard';
import ParticipantList from '../pages/participant/ParticipantList';
import RegistrationScan from '../pages/registrationScan/RegistrationScan';
import Lottery from '../pages/lottery/lottery2';
import Photobooth from '../pages/photobooth/Photobooth';

const auth = new Auth();
const isAuthenticated = auth.isAuthenticated();

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Dashboard',
    component: () => isAuthenticated ? <Redirect to='/dashboard'/> : <Redirect to='/login'/>
  },
  {
    path: '/login',
    exact: true,
    title: 'Login',
    component: () => <Login />
  },
  {
    path: '/dashboard',
    exact: true,
    title: 'Dashboard',
    component: AuthGuard(Dashboard)
  },
  {
    path: '/participant',
    exact: true,
    title: 'List Peserta',
    component: AuthGuard(ParticipantList)
  },
  {
    path: '/scan-qr',
    exact: true,
    title: 'Registrasi QR Code',
    component: AuthGuard(RegistrationScan)
  },
  {
    path: '/lottery',
    exact: true,
    title: 'Undian',
    component: AuthGuard(Lottery)
  },
  {
    path: '/photobooth',
    exact: true,
    title: 'Photo Booth',
    component: AuthGuard(Photobooth)
  },
];


export default routes;
